import { GlobalStateContext } from '@context/GlobalContextProvider';
import { LayoutContextState } from '@context/LayoutContext';
import { useM360UI } from '@madwire/m360ui';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import NavProduct from './NavProduct';

const Nav = ({ isMobile, mobileDrawerOpen, pathname, iconCode, closeNav, currentProduct }) => {
  const { isEmbedded } = useContext(GlobalStateContext);
  const { navigation } = useContext(LayoutContextState);
  const { theme } = useM360UI();

  if (pathname === '/' || !iconCode) {
    return null;
  }

  const displayedNav = <NavProduct closeNav={closeNav} iconCode={iconCode} isMobile={isMobile} />;

  if (isEmbedded) {
    return null;
  }
  return (
    <nav className="site-nav">
      {isMobile ? (
        <Drawer
          className="nav-drawer"
          title={currentProduct}
          placement="left"
          onClose={closeNav}
          open={mobileDrawerOpen}
          width={350}
        >
          <div className="site-mobile-nav-inner u-d-flex">
            {displayedNav}
            {navigation.action && <div>{navigation.action}</div>}
          </div>
        </Drawer>
      ) : (
        <div className="site-nav-inner u-d-flex u-flex-center">
          {displayedNav}
          {navigation.action && <div className="u-mr-4">{navigation.action}</div>}
        </div>
      )}
      <style jsx>{`
        .site-nav {
          position: sticky;
          top: 60px;
          z-index: ${theme.zIndices.sticky};
          background: #fff;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }
        :global(.site-mobile-nav-inner) {
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
        }
        .site-nav-inner {
          height: 100%;
          overflow-y: auto;
          justify-content: space-between;
        }
        @media (min-width: ${theme.breakpoints[1]}) {
          .site-nav {
            width: 100%;
          }
          .site-nav-inner {
            overflow-x: auto;
            overflow-y: hidden;
          }
        }
      `}</style>
    </nav>
  );
};

Nav.propTypes = {
  isMobile: PropTypes.bool,
  mobileDrawerOpen: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  iconCode: PropTypes.string,
  closeNav: PropTypes.func,
  currentProduct: PropTypes.string
};

export default React.memo(Nav);
