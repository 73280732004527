import { handleErrorMessage } from '@utils/global';

export const SEGMENT_PAGE_CATEGORIES = {
  setup: 'Setup',
  settings: 'Settings',
  feature: 'Feature'
};

/**
 * Returns 'setup' if the path contains 'start' or 'setup', 'settings' if the
 * path contains 'settings', and 'feature' otherwise
 * @param {string} path - the path to classify
 * @returns {string} - the category of the path
 */
export const categorizePath = path => {
  const setupPattern = /start|setup|upgrade/;
  const settingsPattern = /settings/;

  if (setupPattern.test(path)) {
    return SEGMENT_PAGE_CATEGORIES.setup;
  }
  if (settingsPattern.test(path)) {
    return SEGMENT_PAGE_CATEGORIES.settings;
  }
  return SEGMENT_PAGE_CATEGORIES.feature;
};

// TODO - review this list, potentially remove
// util object for consistent strings in Segment.com tracking for all products
export const commonSegment = {
  events: {
    clicked: 'Clicked',
    created: 'Created',
    deleted: 'Deleted',
    exported: 'Exported',
    filtered: 'Filtered',
    searched: 'Searched',
    selected: 'Selected',
    updated: 'Updated'
  },
  types: {
    add: 'Add',
    remove: 'Remove',
    pagination: 'Pagination',
    field: 'Field'
  },
  pagination: {
    totalResultCount: 'totalResultCount',
    totalItemsPages: 'totalItemsPages',
    itemsPerPage: 'itemsPerPage',
    currentItemPage: 'currentItemPage'
  }
};

export const segmentProperties = {
  subject: {
    graphMetric: 'Graph Metric',
    filter: 'Filter'
  },
  filterType: {
    dateRange: 'Date Range',
    multiSelect: 'Multi Select',
    radioButton: 'Radio Button',
    dropdown: 'Dropdown',
    slider: 'Slider'
  }
};

// TODO - review this list, potentially remove
// util object for consistent strings in Segment.com tracking for the CRM product
export const crmSegmentProperties = {
  types: {
    associate: 'Associate',
    contact: 'Contact',
    email: 'Email',
    favorite: 'Favorite',
    organization: 'Organization',
    phone: 'Phone',
    recent: 'Recent',
    text: 'Text',
    website: 'Website'
  },
  locations: {
    contactsFeedSection: 'Contacts Feed Section',
    contactsPage: 'Contacts Page',
    detailsSection: 'Details Section',
    profileSection: 'Profile Section',
    singleContactPage: 'Single Contact Page',
    table: 'Table'
  }
};

/**
 * If the window object exists, and the window object has a segment property, and
 * the path of the page being viewed is not in the list of paths to ignore, then
 * identify the user and record the page view.
 *
 * @param {object} data - data to be sent to segment
 * @param {string} data.path - page path
 * @param {object} data.traits - user traits, eg, email, name, etc.
 * @param {string} data.accountNumber - A#
 * @param {string} data.name - The name of the page. Eg, "Signup"
 * @param {string} data.product - The product of the page. Eg, "CRM"
 * @param {string} data.userId - The user id. Eg, "000f26e2013e4848ba5a..."
 *
 */
export const recordSegmentPageView = async ({
  accountNumber,
  name,
  product,
  userId,
  path,
  traits
}) => {
  if (window?.segment && name && product && userId) {
    try {
      await window.segment.identify({
        userId,
        traits
      });
      await window.segment.page({
        accountNumber,
        category: categorizePath(path),
        name,
        product,
        userId
      });
    } catch (error) {
      handleErrorMessage(error);
    }
  }
};

/**
 * Function used to track a Segment Event.
 *
 * @param {object} data - data to be sent to segment
 * @param {object|undefined} data.properties - The properties of the event. Eg, { "location": "Contacts Feed Section" }
 * @param {string|undefined} data.accountNumber - A#
 * @param {string} data.event - The name of the event. Eg, "Clicked"
 * @param {string} data.product - The product of the page. Eg, "CRM"
 * @param {string} data.subject - The subject of the event. Eg, "Contact"
 * @param {string|undefined} data.userId - The user id. Eg, "000f26e2013e4848ba5a..."
 *
 */
export const segmentTrackEvent = ({
  event,
  subject,
  product,
  userId,
  accountNumber,
  properties
}) => {
  if (window?.segment && event && subject && product) {
    window.segment.track({ event, subject, product, userId, accountNumber, properties });
  }
};

export const trackFilterClickSegmentEvent = (eventProperties, currentProduct) => {
  segmentTrackEvent({
    event: commonSegment.events.clicked,
    subject: segmentProperties.subject.filter,
    properties: {
      ...eventProperties,
      path: window && window.location.pathname
    },
    product: currentProduct
  });
};
