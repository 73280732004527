import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import LayoutContextProvider from '@context/LayoutContext';
import Head from 'next/head';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Nav from './Nav';

const productsWithIntegratedSettings = [
  'ads',
  'text',
  'intelligence',
  'reputation',
  'crm',
  'content',
  'social',
  'scheduling',
  'listings',
  'nurture'
];
// Accounts for products with or without nesting.
// i.e. /nurture/text/settings vs. /scheduling/settings
const isSettingsPage = segments => {
  const index = segments.findIndex(segment => segment.toLowerCase() === 'settings');
  if (index === -1) return false;
  return !productsWithIntegratedSettings.includes(segments[index - 1]);
};

const Layout = ({ metaTitle, iconCode, children, router, hideLayoutOnMobile, isStartPage }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const { account, ui, isMobile } = useContext(GlobalStateContext);
  const { mobileDrawerOpen, currentProduct } = ui;

  const [isNavVisible, setIsNavVisible] = useState(false);

  const handleToggleNav = () => setIsNavVisible(!isNavVisible);

  const closeNav = () => dispatch({ type: 'TOGGLE_MOBILE_DRAWER', open: false });

  const urlSegments = router.pathname.split('/');
  const productPath = urlSegments[1] && urlSegments[1].toLowerCase();
  const thisIsStartPage = isStartPage || productPath === 'start';
  const thisIsSettingsPage = isSettingsPage(urlSegments);

  const pageTitle = `${
    account?.displayName ? `${account.displayName} - ` : ''
  }${metaTitle} - Marketing 360®`;

  return (
    <div className={`${isMobile ? 'is-mobile' : 'not-mobile'}`}>
      <LayoutContextProvider isMobile={isMobile}>
        <Head>
          <title>{pageTitle}</title>
        </Head>
        <div className="layout">
          {hideLayoutOnMobile && isMobile ? (
            <main className="site-main">{children}</main>
          ) : (
            <>
              {!thisIsStartPage && !thisIsSettingsPage && (
                <Nav
                  mobileDrawerOpen={mobileDrawerOpen}
                  handleToggleNav={handleToggleNav}
                  pathname={router.pathname}
                  iconCode={iconCode}
                  currentProduct={currentProduct}
                  isMobile={isMobile}
                  closeNav={closeNav}
                />
              )}
              <main className="site-main">{children}</main>
            </>
          )}
        </div>
      </LayoutContextProvider>
      <style jsx>{`
        @media (min-width: 768px) {
          .layout-inner {
            padding-left: 230px;
          }
        }
      `}</style>
    </div>
  );
};

Layout.propTypes = {
  metaTitle: PropTypes.string,
  iconCode: PropTypes.string,
  children: PropTypes.node.isRequired,
  router: PropTypes.object.isRequired,
  hideLayoutOnMobile: PropTypes.bool,
  isStartPage: PropTypes.bool
};

Layout.defaultProps = {
  metaTitle: 'Marketing 360®'
};

export default React.memo(withRouter(Layout));
