import { GlobalStateContext } from '@context/GlobalContextProvider';
import { makePermissionsCheck } from '@utils/permissions';
import { useCallback, useContext } from 'react';

/**
 * This hook returns a checkCan function.
 * The checkCan function takes a string, which is the permission to check for.
 * If the permission is detected in the permissions/productKey object, then true is returned.
 */

const useCanPermissions = () => {
  const { permissions } = useContext(GlobalStateContext);
  const checkCan = useCallback(action => makePermissionsCheck(permissions)(action), [permissions]);

  // Checks an array of bool items.
  const checkPermissionsList = (permissionArray: boolean[]) => {
    if (permissionArray.includes(true)) {
      return true;
    }
    return false;
  };

  return {
    checkCan,
    checkPermissionsList
  };
};

export default useCanPermissions;
