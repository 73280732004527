/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleErrorMessage } from '@utils/global';
import useSWR, { Key } from 'swr';
import { Fetcher, SWRConfiguration, SWRResponse } from 'swr/dist/types';

/**
 * Types
 */
export type SwrRes<T, E = unknown> = SWRResponse<T, E>;
export type SwrMutate<T = unknown> = SWRResponse<T, unknown>['mutate'];

export type UseFetchSwrReturnType<T = any, E = unknown> = SwrRes<T, E> & {
  /** @deprecated - will drop `loading` soon in favor of `isLoading` */
  loading: boolean;
  isLoading: boolean;
  error: string;
  fullError: any;
};

type UseFetchSwrType = <T, E = unknown>(
  key: Key,
  fetcher: Fetcher<T>,
  options?: SWRConfiguration<T, E>
) => UseFetchSwrReturnType<T, E>;

/**
 *
 * Helper
 *
 * See https://swr.vercel.app/docs/options#parameters for argument and return
 * details. This wrapper adds a loading key for convenience and passes any
 * error through the global handle error message.
 *
 */
const useFetchSWR: UseFetchSwrType = (key, fetcher, options) => {
  const { data, error, isValidating, revalidate, mutate } = useSWR(key, fetcher, options);

  const isLoading =
    key !== null &&
    typeof fetcher === 'function' &&
    (data === undefined || data === null) &&
    !error;

  return {
    data,
    error: error ? handleErrorMessage(error) : null,
    fullError: error,
    loading: isLoading,
    isLoading,
    isValidating,
    revalidate,
    mutate
  };
};

export default useFetchSWR;
