import PropTypes from 'prop-types';
import ActiveLink from '../ActiveLink';

const NavProductLink = ({ link, linkUrl, isMobile, pathname, onButtonClick, checkCan }) => (
  <li className={pathname.includes(link.baseLink) ? 'is-active' : ''}>
    {/* We have two levels of 'is-active' so "parent" links remain active on certain pages */}
    <button type="button" onClick={onButtonClick} className="nav-product-links-button">
      <ActiveLink activeClassName="is-active" href={linkUrl}>
        <a style={{ whiteSpace: 'nowrap' }}>{link.title}</a>
      </ActiveLink>
    </button>
    {/*
    On Mobile, we check to see if a link has a subMenu. If it does, we render
    out the subMenu items directly below the parent link.
  */}
    {isMobile && link.subMenu && (
      <ul className="mobile-submenu">
        {link.subMenu.map(subMenuItem => {
          if (!checkCan(subMenuItem.permission)) {
            return null;
          }
          return (
            <li key={subMenuItem.title + subMenuItem.link}>
              <button type="button" onClick={onButtonClick} className="nav-product-links-button">
                <ActiveLink activeClassName="is-active" href={subMenuItem.link}>
                  <a>{subMenuItem.title}</a>
                </ActiveLink>
              </button>
            </li>
          );
        })}
      </ul>
    )}
  </li>
);

NavProductLink.propTypes = {
  link: PropTypes.object,
  linkUrl: PropTypes.string,
  isMobile: PropTypes.bool,
  pathname: PropTypes.string,
  onButtonClick: PropTypes.func,
  checkCan: PropTypes.func
};

export default NavProductLink;
