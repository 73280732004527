import ErrorComponentLarge from '@components/errors/ErrorComponentLarge';
import { captureException } from '@sentry/nextjs';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * ErrorBoundary
 *
 * Wrap around any React component whose potential failure should
 * be compartmentalized.
 *
 * Props:
 *   errorRender - Function returning React component of error screen
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,

      errorEventId: undefined
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });
    this.setState({ errorEventId });
  }

  render() {
    const { hasError, errorEventId } = this.state;
    const { children, errorRender } = this.props;

    // If there's an error, either load custom error display or
    // default ErrorPage component.
    if (hasError) {
      if (errorRender) {
        return errorRender({
          errorEventId
        });
      }
      return <ErrorComponentLarge errorEventId={errorEventId} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
  errorRender: PropTypes.func
};

export default withTranslation(['common'])(ErrorBoundary);
