import theme from '@assets/theme';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

const ErrorComponentLarge = ({ errorEventId }) => {
  const { t } = useTranslation('common');

  return (
    <div className="error-page">
      <div className="background-icon-container">
        <InfoOutlined className="background-icon" style={{ fontSize: 400 }} />
      </div>
      <h1>{t('error-boundary.title')}</h1>
      <p style={{ maxWidth: '450px', width: '90%' }}>
        {t('error-boundary.message')}{' '}
        <button
          className="u-btn-link"
          type="button"
          onClick={() => {
            window.location.reload(true);
          }}
        >
          {t('error-boundary.action')}
        </button>
      </p>
      <p>
        <small>
          <em>
            {t('error-boundary.error-id')} {errorEventId}
          </em>
        </small>
      </p>
      <style jsx>{`
        .error-page {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          min-height: calc(100vh - 60px);
          background: #fafbff;
          text-align: center;
          position: relative;
        }
        .error-page :global(.background-icon-container) {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          pointer-events: none;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.1;
          overflow: hidden;
        }
        .error-page h1 {
          font-weight: bold;
          color: ${theme.colors.text};
          margin-bottom: 0.25em;
          margin-top: 0.25em;
        }
        .error-page button {
          display: inline;
          border: none;
          padding: 0;
          margin: 0;
          color: #1890ff;
          cursor: pointer;
          background: none;
        }
      `}</style>
    </div>
  );
};

ErrorComponentLarge.propTypes = {
  errorEventId: PropTypes.string
};

export default ErrorComponentLarge;
