import { apiCrm } from '@api/crm';
import useFetchSWR from '@hooks/useFetchSWR';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

const useCrmTranslation = () => {
  const { t, i18n } = useTranslation('hot-loaded');

  // load custom keys
  const { data } = useFetchSWR(
    'translation/bulk?words[]=Project&words[]=Projects&words[]=Deal&words[]=Deals',
    async url =>
      (
        await apiCrm({
          url,
          method: 'get'
        })
      )?.data,
    { shouldRetryOnError: false }
  );

  const dealsSingular = t('crm.dealsSingular');
  const dealsPlural = t('crm.dealsPlural');
  const projectsSingular = t('crm.projectsSingular');
  const projectsPlural = t('crm.projectsPlural');

  useEffect(() => {
    if (data && i18n) {
      i18n.addResources('en', 'hot-loaded', {
        'crm.projectsSingular': data.Project,
        'crm.projectsPlural': data.Projects,
        'crm.dealsSingular': data.Deal,
        'crm.dealsPlural': data.Deals
      });
    }
  }, [data, i18n]);

  return {
    dealsSingular,
    dealsPlural,
    projectsSingular,
    projectsPlural
  };
};

export default useCrmTranslation;
