/**
 * Moved from useCanPermissions to share logic in default page wrapper.
 * Provides a permission check callback.
 * @param  {Set<string>} permissions Permissions set.
 * @return {(action:string) => boolean}
 */
export const makePermissionsCheck = permissions => action => {
  if (!permissions || permissions.size === 0) {
    return false;
  }
  return permissions.has(action);
};
