import { GlobalStateContext } from '@context/GlobalContextProvider';
import productsData, { productsSortOrder } from '@data/productsData';
import useCanPermissions from '@hooks/useCanPermissions';
import { useCallback, useContext, useMemo } from 'react';

/**
 * This hook takes in a product key and checks to see if they are in either the active or visible products array.
 *
 * @param {string} product - The product key.
 */

const excludedProducts = ['base'];

const useCanViewProduct = () => {
  const { account } = useContext(GlobalStateContext);
  const { checkCan } = useCanPermissions();
  const products = useMemo(() => (account ? account.products : []), [account]);

  const isProductActive = useCallback(product => {
    if (!product) {
      return false;
    }
    let isActive = false;
    if (product.subProducts) {
      product.subProducts.forEach(subProduct => {
        if (subProduct.status === 'active') {
          isActive = true;
        }
      });
    } else if (product.status === 'active') {
      isActive = true;
    }
    return isActive;
  }, []);
  const isProductVisible = useCallback(
    product => {
      if (!product) {
        return false;
      }
      const productUiData = productsData[product.name];
      let isVisible = false;
      if (product.subProducts) {
        isVisible = product.subProducts.some(subProduct => {
          const subProductUiData = productUiData?.subProducts?.[subProduct?.name];
          return (
            subProduct.visible &&
            subProduct.visibleSetting &&
            (checkCan(subProductUiData?.permissionsKey) || checkCan(`ui_${subProduct.name}_view`))
          );
        });
      } else if (
        product.visible &&
        product.visibleSetting &&
        (checkCan(productUiData?.permissionsKey) || checkCan(`ui_${product.name}_view`))
      ) {
        isVisible = true;
      }
      return isVisible;
    },
    [checkCan]
  );
  const visibleProducts = useMemo(
    () =>
      products
        .filter(product => isProductVisible(product) && product.name !== 'base')
        .sort((a, b) => productsSortOrder.indexOf(a.name) - productsSortOrder.indexOf(b.name)),
    [products, isProductVisible]
  );
  const activeProducts = useMemo(
    () =>
      products.filter(
        product => isProductActive(product) && isProductVisible(product) && product.name !== 'base'
      ),
    [products, isProductActive, isProductVisible]
  );
  const canViewProductByName = useCallback(
    name => {
      let found = false;
      products
        .reduce((acc, product) => {
          // Make an array of all products and subProducts that are visible
          if (product.visible && product.visibleSetting && product.status === 'active') {
            acc.push(product);
          }
          if (product.subProducts) {
            product.subProducts.forEach(subProduct => {
              if (
                subProduct.visible &&
                subProduct.visibleSetting &&
                subProduct.status === 'active'
              ) {
                acc.push(subProduct);
              }
            });
          }
          return acc;
        }, [])
        .forEach(product => {
          if (product.name === name) {
            found = true;
          }
        });
      return found;
    },
    [products]
  );
  return {
    products,
    visibleProducts,
    activeProducts,
    excludedProducts,
    isProductActive,
    isProductVisible,
    canViewProductByName
  };
};

export default useCanViewProduct;
