import { apiAccounts } from '@api/core';
import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import { useToast } from '@madwire/m360ui';
import { handleErrorMessage } from '@utils/global';
import { useCallback, useContext } from 'react';

/**
 * Update the product's setup parameter using the productId and do a global dispatch of the setup.
 */

// Recursion loop to find the setup by productKey
function findProductSetup(products, productKey) {
  for (let i = 0; i < products.length; i += 1) {
    const product = products[i];
    if (product.name === productKey) {
      return product.setup;
    }
    if (product.subProducts) {
      const foundSubProduct = findProductSetup(product.subProducts, productKey);
      if (foundSubProduct) {
        return foundSubProduct;
      }
    }
  }
  return false;
}

const findProduct = (products, key) => products.find(product => product.name === key);

const useProductSetup = () => {
  const { account } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { toast } = useToast();

  /**
   * Finds the product in Global State and returns the entire product object
   * with the setup json parsed.
   * @param {string} productKey
   */
  const getProduct = productKey => {
    const product = { ...findProduct(account?.products, productKey) };

    if (product?.setup) {
      product.setup = JSON.parse(product.setup);
    }

    return product;
  };

  /**
   * Finds the product in Global State and returns setup json parsed.
   * @param {string} productKey
   */
  const getProductSetup = useCallback(
    productKey => {
      if (account && account.products) {
        const setup = findProductSetup(account.products, productKey);
        if (setup) {
          return JSON.parse(setup);
        }
        return null;
      }
    },
    [account]
  );

  /**
   * Finds the product in Global State and returns the isComplete value.
   * @param {string} productKey
   * @returns {boolean}
   */
  const isProductSetupComplete = useCallback(
    productKey => {
      const productSetup = getProductSetup(productKey);
      if (productSetup && productSetup.isComplete === true) {
        return true;
      }
      return false;
    },
    [getProductSetup]
  );

  const updateProductSetup = useCallback(
    async ({ productId, payload }) => {
      try {
        await apiAccounts({
          url: `/products/${productId}`,
          method: 'put',
          withAccountNumber: true,
          payload
        });
        dispatch({ type: 'SET_PRODUCT_SETUP', productId, setup: payload.setup });
      } catch (error) {
        toast({ type: 'error', message: handleErrorMessage(error) });
        console.error(error);
      }
    },
    [dispatch, toast]
  );

  return {
    getProductSetup,
    isProductSetupComplete,
    updateProductSetup,
    getProduct
  };
};

export default useProductSetup;
