import theme from '@assets/theme';
import { useM360UI } from '@madwire/m360ui';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import ActiveLink from '../ActiveLink';

const NavProductSubMenu = ({ subMenu, pathname, checkCan }) => {
  const { theme: m360ui } = useM360UI();
  return (
    <Menu className="nav-product-submenu" mode="horizontal">
      {subMenu.map(subMenuItem => {
        if (!checkCan(subMenuItem.permission)) {
          return null;
        }

        const pathnameArray = pathname.split('/');
        let pathnameBaseLink;
        // Only check for a common "baseLink" on links with 4 levels of navigation.
        if (pathnameArray.length >= 5) {
          // We remove the last segment of the url and rejoin them. This is assuming that
          // all 3rd level nav links will share the same "baseLink" as 4th level nav links.
          pathnameArray.pop();
          pathnameBaseLink = pathnameArray.join('/');
        }

        const isActive =
          pathname === subMenuItem.link || subMenuItem.link.includes(pathnameBaseLink);

        return (
          <Menu.Item
            style={{ margin: 0 }}
            key={subMenuItem.title + subMenuItem.link}
            className={isActive ? 'ant-menu-item-selected' : ''}
          >
            <ActiveLink activeClassName="is-active" href={subMenuItem.link}>
              <a>{subMenuItem.title}</a>
            </ActiveLink>
          </Menu.Item>
        );
      })}
      <style jsx>{`
        @media (min-width: ${m360ui.breakpoints[1]}) {
          :global(.nav-product-submenu) {
            padding: 0 1.42rem;
            background: #f8f8f8;
            border-top: ${theme.border};
          }
          :global(.nav-product-submenu li) {
            padding: 0;
          }
          :global(.nav-product-submenu li a) {
            text-decoration: none;
            padding-left: 0.714rem;
            padding-right: 0.714rem;
          }
        }
      `}</style>
    </Menu>
  );
};

NavProductSubMenu.propTypes = {
  subMenu: PropTypes.array,
  pathname: PropTypes.string,
  checkCan: PropTypes.func
};

export default NavProductSubMenu;
