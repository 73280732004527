/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalContext } from '@context/GlobalContextProvider';
import getCurrentProductKey from '@utils/defaultPage/getCurrentProductKey';
import validateUrl from '@utils/defaultPage/validateUrl';
import { makePermissionsCheck } from '@utils/permissions';
import { recordSegmentPageView } from '@utils/segment';
import { WithTranslation, withTranslation } from 'next-i18next';
import { Component, ReactNode } from 'react';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import PagePermissionDenied from '../components/errors/PagePermissionDenied';
import Layout from '../components/layout/Layout';

interface PageMeta {
  metaTitle?: string;
  iconCode?: string;
  hideLayoutOnMobile?: boolean;
  isStartPage?: boolean;
  permissions?: string[];
}

interface DefaultPageProps extends WithTranslation {
  pathname: string;
  pageMeta: PageMeta;
}

interface DefaultPageState {
  urlValidated: boolean;
  permissionsValidated: boolean;
  hideContent: boolean;
  showPermissionDenied: boolean;
  hasTrackedPage: boolean;
}

const DefaultPage = (
  WrappedComponent: React.ComponentType<any>,
  pageMeta: PageMeta
): React.ComponentType<any> =>
  withTranslation('common')(
    class extends Component<DefaultPageProps, DefaultPageState> {
      // eslint-disable-next-line react/static-property-placement
      static contextType = GlobalContext;

      constructor(props: DefaultPageProps) {
        super(props);
        this.state = {
          hasTrackedPage: false,
          hideContent: false,
          permissionsValidated: false,
          showPermissionDenied: false,
          urlValidated: false
        };
      }

      async componentDidMount(): Promise<void> {
        const { state, dispatch } = this.context;
        const { t, pathname } = this.props;

        const currentProduct = (() => {
          const product = getCurrentProductKey(pathname);
          return t(`products.${product}.title`);
        })();

        if (state.ui.currentProduct !== currentProduct) {
          dispatch({ type: 'SET_CURRENT_PRODUCT', payload: currentProduct });
        }

        this.verifyUrlPermissions();
        this.verifyProductPermissions();
      }

      componentDidUpdate(): void {
        this.handleRecordSegmentPage();
        this.verifyUrlPermissions();
        this.verifyProductPermissions();
      }

      handleRecordSegmentPage = (): void => {
        const { state } = this.context;
        const { t, pathname } = this.props;
        const { hasTrackedPage } = this.state;
        const path = pathname || window.location.pathname;
        const name = pageMeta?.metaTitle;
        const isDashboard = path === '/';

        if (!hasTrackedPage && state.account?.accountNumber && !isDashboard && name) {
          const {
            account: { accountNumber },
            user: {
              user_id: userId,
              email,
              profile_picture: avatar,
              firstName,
              lastName,
              phone_numbers: phoneNumbers
            }
          } = state;

          this.setState(() => {
            recordSegmentPageView({
              accountNumber,
              userId,
              name,
              product: t(`products.${getCurrentProductKey(path)}.title`),
              traits: {
                email,
                avatar,
                firstName,
                lastName,
                phone: phoneNumbers.find((p: { type: string }) => p.type === 'primary')?.number
              },
              path
            });
            return { hasTrackedPage: true };
          });
        }
      };

      verifyUrlPermissions = (): void => {
        const { urlValidated } = this.state;
        const { pathname } = this.props;
        const {
          state: { account }
        } = this.context;

        if (pathname && account && !urlValidated) {
          this.setState({ urlValidated: true });

          const urlValidStatus = validateUrl(pathname, account.products);
          if (urlValidStatus === 'redirecting') {
            this.setState({ hideContent: true });
          }
        }
      };

      verifyProductPermissions = (): void => {
        const { permissionsValidated } = this.state;
        if (!pageMeta?.permissions || permissionsValidated) {
          return;
        }

        const {
          state: { permissions, permissionsDidLoad }
        } = this.context;

        if (!permissionsDidLoad) {
          return;
        }

        if (pageMeta.permissions && permissions) {
          this.setState({ permissionsValidated: true });
          const checkCan = makePermissionsCheck(permissions);
          if (!pageMeta.permissions.every(checkCan)) {
            this.setState({ showPermissionDenied: true });
          }
        }
      };

      generatePageContent = (): ReactNode => {
        const { showPermissionDenied } = this.state;

        if (showPermissionDenied) {
          return <PagePermissionDenied />;
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <WrappedComponent {...this.props} />;
      };

      render(): JSX.Element {
        const {
          state: { account }
        } = this.context;
        const { hideContent } = this.state;
        const showPageContent = !!account && !hideContent;

        const pageContent = this.generatePageContent();

        return (
          <div>
            {showPageContent && (
              <ErrorBoundary>
                {pageMeta ? (
                  <Layout
                    metaTitle={pageMeta.metaTitle || ''}
                    iconCode={pageMeta.iconCode || ''}
                    hideLayoutOnMobile={pageMeta.hideLayoutOnMobile || false}
                    isStartPage={pageMeta.isStartPage || false}
                  >
                    {pageContent}
                  </Layout>
                ) : (
                  pageContent
                )}
              </ErrorBoundary>
            )}
          </div>
        );
      }
    }
  );

export default DefaultPage;
