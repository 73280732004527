/*
  From the Next.js example: https://github.com/zeit/next.js/tree/canary/examples/active-class-name
*/

import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { Children } from 'react';

const isActiveSettingsUrl = (propUrl, currentUrl) =>
  propUrl.includes('settings') && currentUrl.includes('settings');

const ActiveLink = ({ children, activeClassName, ...props }) => {
  const { href, as } = props;
  const { pathname, asPath, ...routerOther } = useRouter();

  const child = Children.only(children);
  let className = child.props.className || null;

  // If the basic path or asPath match
  const pathActive = (pathname === href || isActiveSettingsUrl(pathname, href)) && activeClassName;
  const asPathActive = as && (asPath === as || isActiveSettingsUrl(asPath, as)) && activeClassName;

  if (pathActive || asPathActive) {
    className = `${className !== null ? className : ''} ${activeClassName}`.trim();
  }

  return <Link {...props}>{React.cloneElement(child, { className })}</Link>;
};

ActiveLink.propTypes = {
  activeClassName: PropTypes.string.isRequired
};

export default ActiveLink;
