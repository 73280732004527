import { Box, ButtonPrimary, Container, Flex, Heading, Image } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const PagePermissionDenied = () => {
  const { t } = useTranslation('errors');
  return (
    <Container width="narrow">
      <Flex mx={{ alignItems: 'center', justifyContent: 'center', height: 'full' }}>
        <Box>
          <Image
            mx={{ width: '200px' }}
            src="https://d22if4xzkonim2.cloudfront.net/logo-marketing360.png"
          />
          <Heading type="h1" mx={{ mt: 8, mb: 7 }}>
            {t('permission-denied.title')}
          </Heading>
          <Heading type="h3" mx={{ mb: 9 }}>
            {t('permission-denied.description')}
          </Heading>
          <Link href="/">
            <a>
              <ButtonPrimary>{t('permission-denied.button')}</ButtonPrimary>
            </a>
          </Link>
        </Box>
      </Flex>
    </Container>
  );
};

export default PagePermissionDenied;
