export const crmLinks = ({ deals, projects }) => [
  {
    title: 'Overview',
    link: '/crm/overview',
    permission: 'ui_crm_viewOverview'
  },
  {
    title: 'Leads Inbox',
    link: '/crm/leads-inbox',
    permission: 'ui_crm_viewLeads',
    baseLink: 'leads'
  },
  {
    title: 'Contacts',
    link: '/crm/contacts',
    permission: 'ui_crm_viewContacts',
    baseLink: 'crm/contact'
  },
  {
    title: 'Organizations',
    link: '/crm/organizations',
    permission: 'ui_crm_viewOrganizations'
  },
  {
    title: 'History',
    link: '/crm/history',
    permission: 'ui_crm_viewHistory'
  },
  {
    title: deals,
    link: '/crm/deals',
    permission: 'ui_crm_viewDeals'
  },
  {
    title: projects,
    link: '/crm/projects',
    permission: 'ui_crm_viewProjects'
  },
  {
    title: 'Tasks',
    link: '/crm/tasks',
    permission: 'ui_crm_viewTasks'
  },
  {
    title: 'Calendar',
    link: '/crm/calendar',
    permission: 'ui_crm_viewCalendar'
  },
  {
    title: 'Lists',
    link: '/crm/lists',
    permission: 'ui_crm_viewLists',
    baseLink: 'list'
  },
  {
    title: 'Reports',
    link: '/crm/reports',
    permission: 'ui_crm_viewReports'
  },
  {
    title: 'Settings',
    link: '/crm/settings',
    permission: 'ui_crm_viewSettings'
  }
];

const scheduling = [
  {
    title: 'Overview',
    link: '/scheduling',
    permission: 'ui_scheduling_view'
  },
  // {
  //   title: 'Schedule',
  //   link: '/scheduling/schedule',
  //   permission: 'ui_scheduling_view'
  // },
  {
    title: 'Customers',
    link: '/scheduling/customers',
    permission: 'ui_scheduling_view'
  },
  {
    title: 'Services',
    link: '/scheduling/services',
    permission: 'ui_scheduling_view'
  },
  // {
  //   title: 'Events',
  //   link: '/scheduling/events',
  //   permission: 'ui_scheduling_view'
  // },
  // {
  //   title: 'Classes',
  //   link: '/scheduling/classes',
  //   permission: 'ui_scheduling_view'
  // },
  // {
  //   title: 'Rooms',
  //   link: '/scheduling/rooms',
  //   permission: 'ui_scheduling_view'
  // },
  {
    title: 'Employees',
    link: '/scheduling/employees',
    permission: 'ui_scheduling_view'
  },
  {
    title: 'Booking Forms',
    link: '/forms',
    permission: 'ui_scheduling_view'
  },
  {
    title: 'Settings',
    link: '/scheduling/settings/locations',
    permission: 'ui_scheduling_view'
  }
  // {
  //   title: 'Reports',
  //   link: '/scheduling/reports',
  //   permission: 'ui_scheduling_view'
  // }
];

export const nurture = [
  {
    title: 'Email Marketing',
    link: '/nurture/email',
    baseLink: '/nurture/email',
    parentKey: 'nurture',
    key: 'emailMarketing',
    permission: 'ui_em3_view',
    subMenu: [
      {
        title: 'Overview',
        link: '/nurture/email',
        parentKey: 'nurture',
        key: 'emailMarketing',
        permission: 'ui_em3_view'
      },
      {
        title: 'Email Campaigns',
        link: '/nurture/email/campaigns',
        parentKey: 'nurture',
        key: 'emailMarketing',
        permission: 'ui_em3_view'
      },
      {
        title: 'Automation',
        link: '/nurture/email/automation',
        parentKey: 'nurture',
        key: 'emailMarketing',
        permission: 'ui_em3_view'
      },
      {
        title: 'Email Lists',
        link: '/nurture/email/subscribers',
        parentKey: 'nurture',
        key: 'emailMarketing',
        permission: 'ui_em3_view'
      },
      {
        title: 'Templates',
        link: '/nurture/email/templates',
        parentKey: 'nurture',
        key: 'emailMarketing',
        permission: 'ui_em3_view'
      },
      {
        title: 'Insights',
        link: '/nurture/email/insights',
        parentKey: 'nurture',
        key: 'emailMarketing',
        permission: 'ui_em3_view'
      }
    ]
  },
  {
    title: 'Text Marketing',
    link: '/nurture/text',
    baseLink: '/nurture/text',
    parentKey: 'nurture',
    key: 'smsMarketing',
    setupLink: '/nurture/text/setup',
    permission: 'ui_sms_view',
    subMenu: [
      {
        title: 'How Text Marketing Works',
        link: '/nurture/text',
        parentKey: 'nurture',
        key: 'smsMarketing',
        setupLink: '/nurture/text/setup',
        permission: 'ui_sms_view'
      },
      {
        title: 'Contacts',
        link: '/nurture/text/contacts',
        parentKey: 'nurture',
        key: 'smsMarketing',
        setupLink: '/nurture/text/setup',
        permission: 'ui_sms_view'
      },
      {
        title: 'Lists',
        link: '/nurture/text/lists',
        parentKey: 'nurture',
        key: 'smsMarketing',
        setupLink: '/nurture/text/setup',
        permission: 'ui_sms_view'
      },
      {
        title: 'Automation',
        link: '/nurture/text/automation',
        parentKey: 'nurture',
        key: 'smsMarketing',
        setupLink: '/nurture/text/setup',
        permission: 'ui_sms_view'
      },
      {
        title: 'Messages',
        link: '/nurture/text/messages',
        parentKey: 'nurture',
        key: 'smsMarketing',
        setupLink: '/nurture/text/setup',
        permission: 'ui_sms_view'
      },
      {
        title: 'Birthdays',
        link: '/nurture/text/birthdays',
        parentKey: 'nurture',
        key: 'smsMarketing',
        setupLink: '/nurture/text/setup',
        permission: 'ui_sms_view'
      }
    ]
  },
  {
    title: 'Settings',
    link: '/nurture/settings/text/opt-in-message',
    baseLink: '/nurture/settings',
    parentKey: 'nurture'
  }
];

const listings = [];

const reputation = [
  {
    title: 'Overview',
    link: '/reputation',
    permission: 'ui_reputation_view'
  },
  {
    title: 'Reviews',
    link: '/reputation/reviews',
    permission: 'ui_reputation_viewReviews'
  },
  {
    title: 'Request Reviews',
    link: '/reputation/request-reviews',
    permission: 'ui_reputation_view'
  },
  {
    title: 'Listings',
    link: '/reputation/listings',
    permission: 'ui_reputation_view'
  },
  {
    title: 'Widgets',
    link: '/reputation/widgets',
    permission: 'ui_reputation_view'
  },
  {
    title: 'Settings',
    link: '/reputation/settings',
    permission: 'ui_reputation_view'
  }
];

const social = [
  {
    title: 'Create',
    link: '/social',
    permission: 'ui_social_view'
  },
  {
    title: 'Calendar',
    link: '/social/calendar',
    permission: 'ui_social_view'
  },
  {
    title: 'Reports',
    link: '/social/reports',
    permission: 'ui_social_view'
  },
  {
    title: 'Media',
    link: '/social/media',
    permission: 'ui_social_view'
  },
  {
    title: 'Settings',
    link: '/social/settings',
    permission: 'ui_social_view'
  }
];

const content = [
  {
    title: 'Overview',
    link: '/content',
    permission: 'ui_content_view'
  },
  {
    title: 'Keywords',
    link: '/content/keywords',
    permission: 'ui_content_view'
  },
  {
    title: 'Pages',
    link: '/content/pages',
    permission: 'ui_content_view'
  },
  {
    title: 'Traffic',
    link: '/content/traffic',
    permission: 'ui_content_view'
  },
  {
    title: 'Settings',
    link: '/content/settings',
    permission: 'ui_content_modifySettings'
  }
];

const ads = [
  {
    title: 'Overview',
    link: '/ads',
    permission: 'ui_ads_view'
  },
  {
    title: 'Channels',
    link: '/ads/channels',
    permission: 'ui_ads_view'
  },
  {
    title: 'Campaigns',
    link: '/ads/campaigns',
    permission: 'ui_ads_view'
  },
  {
    title: 'Digital TV Ads',
    link: '/ads/digital-tv-ads',
    permission: 'ui_ads_view'
  },
  {
    title: 'Google Local Service Ads',
    link: '/ads/local-service-ads',
    permission: 'ui_ads_view'
  },
  {
    title: 'Settings',
    link: '/ads/settings',
    permission: 'ui_ads_view'
  }
];

const creative = [
  {
    title: 'Overview',
    link: '/creative',
    permission: 'ui_creative_view'
  },
  {
    title: 'Banner Builder',
    link: '/creative/banner-builder',
    key: 'bannerBuilder',
    permission: 'ui_creative_view'
  },
  {
    title: 'Creative Team',
    link: '/creative/creative-team',
    key: 'todos',
    permission: 'ui_creative_view'
  }
];

const intelligence = [
  {
    title: 'Overview',
    link: '/intelligence',
    permission: 'ui_intelligence_view'
  },
  {
    title: 'Period Reports',
    link: '/intelligence/period-reports',
    baseLink: '/intelligence/period-reports/',
    permission: 'ui_intelligence_view'
  },
  {
    title: 'Conversions Inbox',
    link: '/intelligence/conversions-inbox',
    permission: 'ui_intelligence_view'
  },
  {
    title: 'Site Analytics',
    link: '/intelligence/site-analytics/channels',
    baseLink: '/intelligence/site-analytics/',
    permission: 'ui_intelligence_view'
  },
  {
    title: 'Settings',
    link: '/intelligence/settings/call-tracking',
    permission: 'ui_intelligence_view'
  }
];

const brandProfile = [
  {
    title: 'Brand Profile',
    link: '/brand-profile/overview',
    permission: 'ui_bizInfo_view'
  }
];

const pos = [];

export const navigationLinks = {
  scheduling,
  nurture,
  listings,
  reputation,
  social,
  content,
  ads,
  creative,
  intelligence,
  pos,
  brandProfile
};
