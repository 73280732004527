import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

// Create the Context
export const LayoutContextState = React.createContext();
export const LayoutContextDispatch = React.createContext();

const initialState = {
  navigation: {
    action: null
  },
  isSubMenuBarVisible: true
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_NAVIGATION_ACTION': {
      return {
        ...state,
        navigation: {
          ...state.navigation,
          action: action.payload
        }
      };
    }
    case 'SET_SUB_MENU_BAR_VISIBLE': {
      return {
        ...state,
        isSubMenuBarVisible: action.isVisible
      };
    }
    default:
      throw new Error(`Error in Layout Context, ${action.type} is not defined`);
  }
}

const LayoutContextProvider = ({ children, isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init => ({
    ...init,
    isMobile
  }));

  return (
    <LayoutContextState.Provider value={state}>
      <LayoutContextDispatch.Provider value={dispatch}>{children}</LayoutContextDispatch.Provider>
    </LayoutContextState.Provider>
  );
};

LayoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool
};

export default LayoutContextProvider;
