import theme from '@assets/theme';
import { LayoutContextState } from '@context/LayoutContext';
import { crmLinks, navigationLinks } from '@data/navigationLinks';
import useCrmTranslation from '@hooks/crm/useCrmTranslation';
import useCanPermissions from '@hooks/useCanPermissions';
import useCanViewProduct from '@hooks/useCanViewProduct';
import useProductSetup from '@hooks/useProductSetup';
import { useM360UI } from '@madwire/m360ui';
import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import NavProductLink from './NavProductLink';
import NavProductSubMenu from './NavProductSubMenu';

const NavProduct = ({ iconCode, closeNav, isMobile }) => {
  const { pathname } = useRouter();
  const { dealsPlural, projectsPlural } = useCrmTranslation();
  const { isSubMenuBarVisible } = useContext(LayoutContextState);
  const { theme: m360ui } = useM360UI();

  const { canViewProductByName } = useCanViewProduct();

  const { checkCan } = useCanPermissions();
  const { isProductSetupComplete } = useProductSetup();

  let links;

  // We use the 'crmLinks' function only if the product is CRM because
  // the "Deals" and "Projects" could have different custom titles.
  if (iconCode === 'crm') {
    links = crmLinks({
      deals: dealsPlural,
      projects: projectsPlural
    });
  } else {
    links = navigationLinks[iconCode];
  }

  let subMenu;

  // This will generate the JSX for the main product navigation.
  const renderedLinks = links.map(link => {
    if (link.permission && !checkCan(link.permission)) {
      return null;
    }
    // Only child products will have a 'key'. Checks to see if we can
    // view a child product by its 'key', hide in the nav if cannot.
    if (link.key && !canViewProductByName(link.key)) {
      return null;
    }
    if (link.title === '...') {
      link.title = (
        <div style={{ width: '60px' }}>
          <Skeleton title={false} avatar={false} active paragraph={{ rows: 1 }} />
        </div>
      );
    }

    // This currently only applies to the Nurture subProducts.
    // We need to check the subProducts separately to see if setup is complete.
    let linkUrl = link.link;
    if (link.parentKey && !isProductSetupComplete(link.key)) {
      linkUrl = link.setupLink || link.link;
    } else if (link.subMenu && pathname.includes(link.baseLink)) {
      // All subMenu links should have the same "base" link as its parent.
      // For example, '/nurture/text' as the parent and '/nurture/text/contact' as a child.
      // This ensures that a subMenu will only show up on the proper pages.

      // Destructuring the "let subMenu" requires the parenthesis.
      ({ subMenu } = link);
    }

    return (
      <NavProductLink
        key={link.title + link.link}
        link={link}
        linkUrl={linkUrl}
        isMobile={isMobile}
        pathname={pathname}
        onButtonClick={closeNav}
        checkCan={checkCan}
      />
    );
  });

  return (
    <nav className="nav-product">
      <ul className="nav-product-links">{renderedLinks}</ul>
      {isSubMenuBarVisible && !isMobile && subMenu && (
        <NavProductSubMenu subMenu={subMenu} pathname={pathname} checkCan={checkCan} />
      )}

      <style jsx>{`
        .nav-product {
          width: 100%;
        }
        .nav-product-button {
          display: flex;
          align-items: center;
          width: 100%;
          background: none;
          border: none;
          cursor: pointer;
        }
        .nav-product-links {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        .nav-product-links :global(li a) {
          display: flex;
          align-items: center;
          padding: 1rem 0;
          color: ${theme.colors.text};
          text-decoration: none;
          transition: color ${theme.transitionEffect};
        }
        .nav-product-links :global(li a:hover),
        .nav-product-links :global(li a:focus) {
          color: ${theme.colors.primary};
        }
        .nav-product-links :global(li.is-active > a),
        .nav-product-links :global(li a.is-active) {
          font-weight: bold;
          color: ${theme.colors.primary};
        }
        .nav-product-links :global(.nav-product-links-button) {
          padding: 0;
          height: 100%;
          background: none;
          border: none;
        }
        .nav-product-links :global(.mobile-submenu) {
          padding-left: 1.5rem;
          list-style: none;
        }
        .nav-product-links :global(.ant-skeleton-paragraph) {
          padding: 0;
        }
        .nav-product-links :global(.ant-skeleton-paragraph > li) {
          padding: 0;
          width: 100% !important;
        }
        @media (min-width: ${m360ui.breakpoints[1]}) {
          .nav-product-links {
            display: flex;
            padding: 0 1.42rem;
          }
          .nav-product-links :global(li a) {
            display: flex;
            align-items: center;
            padding: 1.5rem 0.714rem;
          }
          .nav-product-links :global(li.is-active a),
          .nav-product-links :global(li a.is-active) {
            color: ${theme.colors.primary};
            box-shadow: 0 -2px 0 0 ${theme.colors.primary} inset;
            font-weight: 400;
          }
        }
      `}</style>
    </nav>
  );
};

NavProduct.propTypes = {
  iconCode: PropTypes.string,
  closeNav: PropTypes.func,
  isMobile: PropTypes.bool
};

NavProduct.defaultProps = {
  iconCode: 'crm'
};

export default React.memo(NavProduct);
